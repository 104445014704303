<script lang="ts" setup>
import ConfigNavigationStepOne from './ConfigNavigationStepOne.vue'
import ConfigNavigationStepTwo from './ConfigNavigationStepTwo.vue'
import ConfigNavigationStepThree from './ConfigNavigationStepThree.vue'
import ConfigNavigationStepFour from './ConfigNavigationStepFour.vue'
import ConfigNavigationStepFive from './ConfigNavigationStepFive.vue'

const { activeTab, inactiveTabs, doneTabs } = storeToRefs(useConfigNavigation())

const { user } = storeToRefs(useUser())
const { caseConfig } = storeToRefs(useCaseConfig())

const { addToCart } = useCart()

const { openLogin } = useModals()

const stepComponents = [ConfigNavigationStepOne, ConfigNavigationStepTwo, ConfigNavigationStepThree, ConfigNavigationStepFour, ConfigNavigationStepFive]

const currentStepComponent = computed(() => stepComponents[doneTabs.value.length])
</script>

<template>
  <div v-auto-animate w="full md:320px" shadow-none md="card-shadow!" rounded-12px overflow-y-auto max-h-800px un-scrollbar="~ w-4px track-color-white thumb-color-app-stroke rounded radius-10px">
    <ConfigNavigationTab v-for="tab in doneTabs" :key="tab.no" :tab="tab" p-12px />
    <ConfigNavigationTab
      :tab="activeTab"
      :class="[doneTabs.length === 0 ? 'border-b-1px border-b-app-stroke' : 'bg-app-bg-card border-t-1px border-t-app-stroke']"
      p-12px
    />
    <div bg-app-bg-card border-b="1px app-stroke" :class="{ 'rounded-b-12px': activeTab.no === 5 }" divide-y>
      <component :is="currentStepComponent" p-16px />
      <div v-if="activeTab.no === 5" pb-24px pt-8px flex flex-col items-center justify-center>
        <div flex flex-col items-center gap-8px py-16px border-b w-full>
          <h2 text-14px font-semibold>
            {{ user ? user.fullName : 'Nepřihlášený uživatel' }}
          </h2>
          <button type="button" text-btn @click="user ? undefined : openLogin()">
            {{ user ? user.email : 'Přihlásit se' }}
          </button>
        </div>
        <p v-if="caseConfig.isCustomDimensions && !caseConfig.casePrice" text-14px text-center max-w-320px whitespace-pre-wrap px-24px pt-24px>
          Tato konfigurace má atypické rozměry, pro získání ceny nás prosím kontaktujte na email info@kmfire.cz. Případně můžete odeslat objednávku, na základě které Vás budeme kontaktovat s doplněnou cenou za konfiguraci.
        </p>
        <h1 text="14px app-text-black" font-500 leading-20px mt-16px>
          Celková cena za konfiguraci
        </h1>
        <h2 text="18px black" leading-24px font-700>
          {{ formatPrice(caseConfig.casePrice) }} Kč
        </h2>
        <div px-28px py-10px bg-app-red text="14px white" leading-20px font-700 rounded-full mt-24px cursor-pointer @click="addToCart(caseConfig)">
          PŘIDAT KONFIGURACI DO KOŠÍKU
        </div>
      </div>
    </div>
    <div flex flex-col gap-12px :class="{ 'p-12px': inactiveTabs.length > 0 }">
      <ConfigNavigationTab v-for="tab in inactiveTabs" :key="tab.no" :tab="tab" />
    </div>
  </div>
</template>
