<script lang="ts" setup>
</script>

<template>
  <div flex flex-col items-center gap-12px>
    <h1 font-700 whitespace-pre-wrap text-center max-w-220px>
      Pro tento typ skříně se prostupy neupravují.
    </h1>
  </div>
</template>
