<script lang="ts" setup>
import { ModalsContainer } from 'vue-final-modal'
import { useMediaQuery } from '@vueuse/core'

const mobileView = ref<'params' | 'view'>('params')

const isNotMobile = useMediaQuery('(min-width: 768px)')
</script>

<template>
  <div flex flex-col justify-between min-h-full>
    <div max-h-full>
      <Header show-tabs fixed z-100 w-full />
      <ClientOnly>
        <div flex items-start pl="0 md:24px" pt="0 md:24px" gap-32px h-full mt-64px>
          <ConfigNavigation v-if="mobileView === 'params' || isNotMobile" top-112px lg:top-88px fixed max-h-85vh />
          <div overflow-auto md:left-400px left-0 right-0 top-112px lg:top-88px absolute bottom-120px md:bottom-0 md:pr-32px un-scrollbar="~ w-4px track-color-white thumb-color-app-stroke rounded radius-10px">
            <NuxtPage v-if="mobileView === 'view' || isNotMobile" />
          </div>
        </div>
      </ClientOnly>
    </div>
    <div fixed bottom-0 flex items-center justify-center gap-32px md:hidden h-72px bg-white w-full mobile-navigation-shadow mt-32px>
      <button type="button" flex flex-col items-center gap-4px text-app-text-black :class="{ 'text-app-blue!': mobileView === 'params' }" focus:outline-none @click="mobileView = 'params'">
        <UnoIcon i-heroicons-solid-adjustments-horizontal text="24px" />
        <p text-10px font-500 leading-16px>
          Parametry skříně
        </p>
      </button>
      <button type="button" flex flex-col items-center gap-4px text-app-text-black :class="{ 'text-app-blue!': mobileView === 'view' }" focus:outline-none @click="mobileView = 'view'">
        <UnoIcon i-heroicons-outline-squares-2x2 text="24px" />
        <p text-10px font-500 leading-16px>
          Náhled a editace
        </p>
      </button>
    </div>
    <ModalsContainer />
  </div>
</template>
