<script lang="ts" setup>
import { promiseTimeout } from '@vueuse/core'

const { generatingPdf, printingPdf, getPdf, screenshotCases } = usePdf()

const { caseConfig } = storeToRefs(useCaseConfig())

onMounted(async () => {
  caseConfig.value.optimizeScale()

  let zoomOut = 0.0
  if (caseConfig.value.caseInfo!.outDimensions.h > 1000)
    zoomOut = 0.20
  if (caseConfig.value.caseInfo!.outDimensions.h > 1700)
    zoomOut = 0.38
  if (caseConfig.value.caseInfo!.outDimensions.h > 1800)
    zoomOut = 0.47

  caseConfig.value.scale! -= zoomOut
  caseConfig.value.scale! += 1
  await promiseTimeout(300)
  await screenshotCases(caseConfig.value)
})
</script>

<template>
  <div flex gap-12px items-start>
    <div w-40px h-40px>
      <UnoIcon i-heroicons-solid-information-circle text="app-text-icon 32px" />
    </div>
    <div flex flex-col gap-12px>
      <p text="12px app-text-black " leading-16px font-400 max-w-244px whitespace-pre-wrap>
        Vytvořenou konfiguraci si
        můžete prohlédnout vpravo, vytisknout nebo stáhnout v PDF
      </p>
      <div
        border="1px app-stroke"
        app-btn cursor-pointer leading-16px text-center font-600
        py-12px w-full flex items-center justify-center @click="getPdf(caseConfig)"
      >
        <UnoIcon v-if="generatingPdf" i-mdi-loading animate-spin />
        <p v-else>
          Stáhnout PDF
        </p>
      </div>
      <div
        border="1px app-stroke"
        bg-white cursor-pointer text="14px black" leading-16px font-600 rounded-full text-center
        py-12px w-full flex items-center justify-center @click="getPdf(caseConfig, {
          print: true,
        })"
      >
        <UnoIcon v-if="printingPdf" i-mdi-loading animate-spin />
        <p v-else>
          Vytisknout
        </p>
      </div>
    </div>
  </div>
</template>
