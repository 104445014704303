<script lang="ts" setup>
import { CableHoleType } from '~/types/CableHole'

const { caseConfig } = storeToRefs(useCaseConfig())

const options = [
  {
    title: 'Prostupový tunýlek TKM',
    value: CableHoleType.tkm,
  },
  {
    title: 'Prostup kabelovou ucpávkou UKM',
    value: CableHoleType.ukm,
  },
]

function changeType (type: CableHoleType) {
  caseConfig.value.cableHoleType = type
  caseConfig.value.cableHoles = []
}
</script>

<template>
  <div flex flex-col items-center gap-12px>
    <SelectablePill
      v-for="option in options" :key="option.value" :label="option.title"
      :is-active="option.value === caseConfig.cableHoleType" side-padding="24px" @click="changeType(option.value)"
    />
    <UnoIcon i-heroicons-solid-information-circle text="app-text-icon 40px" mt-8px />
    <h1 v-if="!caseConfig.cableHoleType" font-700 whitespace-pre-wrap text-center max-w-220px>
      Prosím vyberte typ provedení prostupu pro kabely
    </h1>
    <h1 font-500 whitespace-pre-wrap text-center max-w-254px mt-12px>
      <template v-if="caseConfig.cableHoleType === CableHoleType.tkm">
        Prostup TKM je tvořen prostupovým
        tunýlkem, který je třeba na stavbě utěsnit protipožární ucpávkou - provádí montážní
        firma.
      </template>
      <template v-if="caseConfig.cableHoleType === CableHoleType.ukm">
        Prostup UKM je tvořen intumescentním
        blokem a minerální vatou. Skrze tento prostup se na stavbě snadnou protáhnou
        kabely a není třeba dalšího utěsnění.
      </template>
    </h1>
  </div>
</template>
