<script lang="ts" setup>
import { Fill, ToolGridContent } from '~/types/Fill'
import { Mask, MaskType } from '~/types/Mask'

const options = computed<{ id: number, title: string, disabled?: boolean }[]>(() =>
  [
    { id: ToolGridContent.full, title: 'Komplet', disabled: caseConfig.value.isCustomDimensions },
    { id: ToolGridContent.onlySides, title: 'Pouze bočnice', disabled: caseConfig.value.isCustomDimensions },
    { id: ToolGridContent.onlyPrepare, title: 'Pouze příprava' },
  ],
)

const { changeFill } = useCaseConfig()
const { caseConfig, configsInitialized } = storeToRefs(useCaseConfig())
const { masks } = storeToRefs(useRemoteConfig())

const selectedToolgridType = ref<ToolGridContent>(options.value[0].id)

const [addMasks, toggleAddMasks] = useToggle(false)

const masksType = ref<MaskType>(MaskType.cutOut)

watch(selectedToolgridType, () => {
  if (selectedToolgridType.value === ToolGridContent.onlyPrepare)
    setOnlyPrepare()
  else
    changeToolgridContent(selectedToolgridType.value)
})

function changeToolgridContent (content: ToolGridContent) {
  if (!caseConfig.value.caseInfo!.orderNumber)
    return

  caseConfig.value.fill!.asToolGrid.masks = []
  caseConfig.value.fill!.asToolGrid.content = content
}

const availableMasks = computed(() => {
  const masksList = masks.value
    .filter(m => m.fill.includes(caseConfig.value.fill!.asInt!))
    .map(mask => {
      const maskCopy = new Mask(mask.type, mask.label, mask.height, mask.fill)
      maskCopy.label = maskCopy.label.replaceAll(
        'XXX',
        (Math.floor(caseConfig.value.caseInfo!.outDimensions.w / 100) * 100)
          .toString())

      return maskCopy
    })

  const freeSpace = caseConfig.value.freeSpaceInToolGrid ?? 0

  return masksList
    .filter(m => m.type === masksType.value && m.height <= freeSpace)
})

function autoAddMasks () {
  while (availableMasks.value.length > 0) {
    const mask = availableMasks.value.find(m => m.height === caseConfig.value.freeSpaceInToolGrid)
      || availableMasks.value.find(m => m.height === caseConfig.value.freeSpaceInToolGrid! / 2)
      || availableMasks.value.find(m => m.height === 150)
      || availableMasks.value[availableMasks.value.length - 1]
    addMaskToGrid(
      mask,
    )
  }

  if (caseConfig.value.freeSpaceInToolGrid! > 0) {
    masksType.value = masksType.value === MaskType.cutOut ? MaskType.filled : MaskType.cutOut
    autoAddMasks()
  }
}

function addMaskToGrid (mask: Mask) {
  caseConfig.value.ventilations = []
  caseConfig.value.fill!.asToolGrid.addMask(mask)
}

function setOnlyPrepare (): void {
  changeFill(Fill.toolGrid)
  changeToolgridContent(ToolGridContent.onlyPrepare)
}

onMounted(async () => {
  await until(configsInitialized).toBe(true)
  selectedToolgridType.value = caseConfig.value.fill!.asToolGrid.content!

  if (caseConfig.value.isCustomDimensions)
    setOnlyPrepare()
})
</script>

<template>
  <div v-auto-animate bg-white rounded-b-xl>
    <KRadio v-model="selectedToolgridType" :options="options" name="toolgrid-type" p-16px border-b :class="{ 'rounded-b-12px': !caseConfig.isCustomDimensions }" />
    <div v-if="selectedToolgridType === options[0].id" cursor-pointer flex items-center gap-12px px-16px pt-16px pb-12px w-auto @click="toggleAddMasks()">
      <div flex items-center justify-center h-18px w-18px border-2px :class="[addMasks ? 'border-app-blue bg-app-blue' : 'border-app-stroke bg-white']" rounded-sm>
        <UnoIcon v-if="addMasks" i-heroicons-solid-check text="white 16px" font-800 />
      </div>
      <span class="text-14px text-black leadinh-16px" :class="[addMasks ? 'font-700' : 'font-400']">Přidat krycí desky</span>
    </div>
    <div v-if="addMasks" grid grid-cols-2 items-center>
      <div
        py-12px text-center border-b-2px :class="[masksType === MaskType.cutOut ? 'text-app-blue font-700 border-app-blue border-b-2px' : 'text-app-text-black font-500 border-app-stroke border-b-1px']"
        text-12px
        leading-16px cursor-pointer @click="masksType = MaskType.cutOut"
      >
        S VÝŘEZEM
      </div>
      <div
        py-12px text-center :class="[masksType === MaskType.filled ? 'text-app-blue font-700 border-app-blue border-b-2px' : 'text-app-text-black font-500 border-app-stroke border-b-1px']"
        text-12px
        leading-16px cursor-pointer @click="masksType = MaskType.filled"
      >
        BEZ VÝŘEZU
      </div>
    </div>
    <div v-if="addMasks" flex flex-col gap-16px p-16px>
      <h1 cursor-pointer text="12px app-blue" leading-16px font-500 @click="autoAddMasks()">
        DOPLNIT ROŠT AUTOMATICKY
      </h1>
      <div v-for="mask in availableMasks" :key="mask.label" flex items-center justify-between @click="addMaskToGrid(mask)">
        <h2 text="12px black" leading-16px font-500>
          {{ mask.label }}
        </h2>
        <UnoIcon cursor-pointer i-heroicons-solid-plus-circle text="16px app-blue" />
      </div>
    </div>
    <p v-if="caseConfig.isCustomDimensions" text-13px underline whitespace-normal p-12px>
      Pro možnost instalace roštu prosím vyberte katalogové rozměry
    </p>
  </div>
</template>
