<script lang="ts" setup>
import { electricityRequirementInsideHeights } from '~/types/CaseConfig'

const { caseConfig } = storeToRefs(useCaseConfig())

const possibleHeights = computed(() => {
  const heights = (caseConfig.value.isSr ? electricityRequirementInsideHeights.sr : electricityRequirementInsideHeights.other).map(h => `${h}`)
  const allExceptLast = heights.splice(0, heights.length - 1)
  const last = heights.reverse()[0]
  return `${allExceptLast.join(', ')} nebo ${last}`
})
</script>

<template>
  <div flex flex-col items-center p-16px>
    <template v-if="caseConfig.isElectricityAvailable">
      <UnoIcon i-heroicons-solid-information-circle text="40px app-text-icon" />
      <h1 text="14px black" leading-20px font-700 text-center whitespace-nowrap mt-12px>
        Jde pouze o ilustrativní návrh
      </h1>
      <h2 text="14px black" leading-20px font-500 text-center whitespace-pre-wrap mt-4px>
        Skříň bude obsahovat montážní panel pro umístění vlastních komponent
      </h2>
    </template>
    <template v-else>
      <UnoIcon i-heroicons-solid-exclamation-triangle text="40px app-red" />
      <h1 text="12px app-red" leading-20px font-700 text-center mt-12px>
        Elektroměrovou přípravu nelze umístit
      </h1>
      <h2 text="12px black" leading-20px font-500 text-center whitespace-pre-wrap mt-4px>
        Pro možnost instalace elektroměrové přípravy prosím vyberte katalogové rozměry výšky
        <br>
        {{ possibleHeights }}mm
      </h2>
    </template>
  </div>
</template>
