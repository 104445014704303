<script lang="ts" setup>
const { nextTab } = useConfigNavigation()
const { caseConfig } = storeToRefs(useCaseConfig())
</script>

<template>
  <div>
    <div flex flex-col items-center gap-12px text="14px black" leading-20px>
      <CableHoleSelectionSp v-if="caseConfig.isSp" />
      <CableHoleSelectionSr v-if="caseConfig.isSr" />
      <CableHoleSelectionSz v-if="caseConfig.isSz" />
    </div>
    <button type="button" app-btn w-full mt-24px @click="nextTab()">
      Pokračovat
    </button>
  </div>
</template>
