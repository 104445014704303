<script lang="ts" setup>
const { nextTab } = useConfigNavigation()
const { caseConfig } = useCaseConfig()
</script>

<template>
  <div>
    <div flex flex-col items-center gap-12px text="14px black" leading-20px mt-8px>
      <UnoIcon i-heroicons-solid-information-circle text="app-text-icon 40px" />
      <template v-if="caseConfig.ventilationForCaseAllowed">
        <template v-if="caseConfig.hasMasks">
          <h1 font-700>
            Do dveří skříně nelze umístit ventilátor.
          </h1>
          <h2 font-500 whitespace-pre-wrap text-center max-w-254px>
            Mohou zde být pouze samostatné větrací mřířky. Pro umístění ventilátoru je třeba odebrat krycí desky v kroku 2.
          </h2>
        </template>
        <template v-else>
          <h2 font-500 whitespace-pre-wrap text-center max-w-254px>
            Ventilaci prosím umístěte pomocí konfigurátoru v pravé části.
          </h2>
        </template>
      </template>
      <template v-else>
        <h1 font-700>
          Do této skříně nelze osadit ventilaci.
        </h1>
        <h2 font-500 whitespace-pre-wrap text-center max-w-254px>
          Tato skříň není dostatečně vysoká na osazení ventilačního systému
        </h2>
      </template>
    </div>
    <button type="button" app-btn w-full mt-24px @click="nextTab()">
      Pokračovat
    </button>
  </div>
</template>
