<script lang="ts" setup>
import { SizeType } from '~/types/SizeType'

defineProps<{
  tab: NavigationTab
}>()

const { selectTab } = useConfigNavigation()
const { caseConfig } = storeToRefs(useCaseConfig())

const classes = {
  active: {
    box: 'bg-app-red text-white font-900 text-18px leading-20px',
    title: 'text-black font-900 text-14px leading-20px',
    subtitle: 'text-app-text-black font-500 text-12px leading-16px',
  },
  done: {
    box: 'bg-app-green text-white font-900 text-18px leading-20px',
    title: 'text-black font-900 text-14px leading-20px',
    subtitle: 'text-black font-500 text-12px leading-16px',
  },
  inactive: {
    box: 'bg-app-bg-gray text-app-text-black font-400 text-14px leading-20px',
    title: 'text-black font-500 text-14px leading-20px',
    subtitle: 'text-app-text-black font-500 text-12px leading-16px',
  },
}
</script>

<template>
  <div flex items-center gap-12px cursor-pointer @click="selectTab(tab.no)">
    <div :class="classes[tab.state].box" flex items-center justify-center w-40px h-40px rounded-8px>
      {{ tab.no }}
    </div>
    <div flex flex-col items-start gap-4px>
      <h1
        :class="[classes[tab.state].title, { 'text-app-text-black! text-12px! font-500! leading-16px!': tab.no === 5 }]"
      >
        {{ tab.title }} <span v-if="tab.no === 1 && caseConfig.sizeType === SizeType.custom" text-app-red text-10px>- VLASTNÍ ROZMĚR</span>
      </h1>
      <h2 :class="[classes[tab.state].subtitle, { 'text-black! font-700! text-14px! leading-20px!': tab.no === 5 }]">
        {{ tab.no === 5 && caseConfig?.casePrice ? `${formatPrice(caseConfig.casePrice)} Kč` : tab.subtitle }}
      </h2>
    </div>
  </div>
</template>
