<script lang="ts" setup>
const props = defineProps<{
  modelValue: string | number
  options: { id: number | string, title: string, disabled?: boolean }[]
  name: string
}>()

const emits = defineEmits(['update:modelValue'])

const value = useVModel(props, 'modelValue', emits)

function selectOption (option: { id: number | string, title: string, disabled?: boolean }) {
  if (!option.disabled)
    value.value = option.id
}
</script>

<template>
  <fieldset>
    <div space-y-16px>
      <div v-for="option in options" :key="option.id" flex items-center :class="{ 'cursor-pointer': !option.disabled }" @click="selectOption(option)">
        <div flex items-center justify-center h-16px w-16px rounded-full border-2px :class="[option.id === value ? 'border-app-blue bg-app-blue' : 'border-app-stroke bg-white']">
          <div v-if="option.id === value" w-6px h-6px bg-white rounded-full />
        </div>
        <label :for="`${option.id}`" :class="{ 'text-app-stoke line-through': option.disabled }" text="14px app-text-dark" leading-16px font-500 ml-12px block>{{ option.title }}</label>
      </div>
    </div>
  </fieldset>
</template>
