<template>
  <div flex flex-col items-center p-16px bg-app="blue/10">
    <UnoIcon i-heroicons-solid-check-circle text="40px app-blue" />
    <h1 text="14px black" leading-20px font-500 text-center whitespace-pre-wrap mt-8px>
      Vybrali jste <span underline>Pouze dveře</span>.
      <br>
      Skříň neobsahuje ochranný kryt, do kterého lze umístit výplň
    </h1>
  </div>
</template>
